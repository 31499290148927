import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, Portfolio, About, Services, Contact, Detail } from './pages';
import { Navbar } from './layouts';

const App = () => {
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path={'/'} element={<Home/>} />
                <Route path={'/portfolio'} element={<Portfolio/>}/>
                <Route path={'/portfolio/:id'} element={<Detail/>} />
                <Route path={'/about'} element={<About/>} />
                <Route path={'/services'} element={<Services/>} />
                <Route path={'/contact'} element={<Contact/>} />
            </Routes>
        </Router>
    )
};

export default App;