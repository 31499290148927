// Coffee Point
import coffee1 from '../images/coffepoint/coffeepoint-6.jpg';
import coffee2 from '../images/coffepoint/coffeepoint-2.jpg';
import coffee3 from '../images/coffepoint/coffeepoint-3.jpg';
import coffee4 from '../images/coffepoint/coffeepoint-4.jpg';
import coffee5 from '../images/coffepoint/coffeepoint-5.jpg';
import coffee6 from '../images/coffepoint/coffeepoint-1.jpg';
import coffee7 from '../images/coffepoint/coffeepoint-7.jpg';
import coffee8 from '../images/coffepoint/coffeepoint-8.jpg';

// Nur
import nur1 from '../images/nur/1.jpg';
import nur2 from '../images/nur/2.jpg';
import nur3 from '../images/nur/3.jpg';
import nur4 from '../images/nur/3.jpg';
import nur5 from '../images/nur/4.jpg';
import nur6 from '../images/nur/5.jpg';
import nur7 from '../images/nur/6.jpg';
import nur8 from '../images/nur/7.jpg';
import nur9 from '../images/nur/8.jpg';
import nur10 from '../images/nur/10.jpg';
import nur11 from '../images/nur/11.jpg';
import nur12 from '../images/nur/12.jpg';
import nur13 from '../images/nur/13.jpg';
import nur14 from '../images/nur/14.jpg';
import nur15 from '../images/nur/15.jpg';
import nur16 from '../images/nur/16.jpg';
import nur17 from '../images/nur/17.jpg';
import nur18 from '../images/nur/18.jpg';
import nur19 from '../images/nur/19.jpg';


// Vip Zone
import vip1 from '../images/vipzone/vip1.jpg';
import vip2 from '../images/vipzone/vip2.jpg';
import vip3 from '../images/vipzone/vip3.jpg';
import vip4 from '../images/vipzone/vip4.jpg';
import vip5 from '../images/vipzone/vip5.jpg';
import vip6 from '../images/vipzone/vip6.jpg';

// Shor KW
import shor from '../images/shor/shor3.jpg';
import shor1 from '../images/shor/shor1.jpg';
import shor2 from '../images/shor/shor2.jpg';
import shor3 from '../images/shor/shor.jpg';

// 30mk
import mkr from '../images/30mk/30mk.jpg';
import mkr1 from '../images/30mk/1.jpg';
import mkr2 from '../images/30mk/2.jpg';
import mkr3 from '../images/30mk/3.jpg';
import mkr4 from '../images/30mk/4.jpg';
import mkr5 from '../images/30mk/5.jpg';
import mkr6 from '../images/30mk/6.jpg';

// 1park
import park from '../images/1park/park.jpg';
import park1 from '../images/1park/park1.jpg';
import park2 from '../images/1park/park2.jpg';
import park3 from '../images/1park/park3.jpg';
import park4 from '../images/1park/park4.jpg';
import park5 from '../images/1park/park5.jpg';
import park6 from '../images/1park/park6.jpg';
import park7 from '../images/1park/park7.jpg';
import park8 from '../images/1park/park8.jpg';


// 15 tapgyr
import tap from '../images/15tapgyr/tap.jpg';
import tap1 from '../images/15tapgyr/tap1.jpg';
import tap2 from '../images/15tapgyr/tap2.jpg';
import tap3 from '../images/15tapgyr/tap3.jpg';
import tap4 from '../images/15tapgyr/tap4.jpg';
import tap5 from '../images/15tapgyr/tap5.jpg';
import tap6 from '../images/15tapgyr/tap6.jpg';
import tap7 from '../images/15tapgyr/tap7.jpg';
import tap8 from '../images/15tapgyr/tap8.jpg';
import tap9 from '../images/15tapgyr/tap9.jpg';
import tap10 from '../images/15tapgyr/tap10.jpg';
import tap11 from '../images/15tapgyr/tap11.jpg';

// Nur Karaoke
import nurka from '../images/Nur Karaoke/nurka.jpg';
import nurka1 from '../images/Nur Karaoke/nurka1.jpg';
import nurka2 from '../images/Nur Karaoke/nurka2.jpg';
import nurka3 from '../images/Nur Karaoke/nurka3.jpg';
import nurka4 from '../images/Nur Karaoke/nurka4.jpg';
import nurka5 from '../images/Nur Karaoke/nurka5.jpg';
import nurka6 from '../images/Nur Karaoke/nurka6.jpg';
import nurka7 from '../images/Nur Karaoke/nurka7.jpg';
import nurka8 from '../images/Nur Karaoke/nurka8.jpg';
import nurka9 from '../images/Nur Karaoke/nurka9.jpg';
import nurka10 from '../images/Nur Karaoke/nurka10.jpg';
import nurka11 from '../images/Nur Karaoke/nurka11.jpg';
import nurka12 from '../images/Nur Karaoke/nurka12.jpg';
import nurka13 from '../images/Nur Karaoke/nurka13.jpg';
import nurka14 from '../images/Nur Karaoke/nurka14.jpg';
import nurka15 from '../images/Nur Karaoke/nurka15.jpg';
import nurka16 from '../images/Nur Karaoke/nurka16.jpg';
import nurka17 from '../images/Nur Karaoke/nurka17.jpg';
import nurka18 from '../images/Nur Karaoke/nurka18.jpg';
import nurka19 from '../images/Nur Karaoke/nurka19.jpg';

// Masha Boutique
import masha from '../images/masha/masha.jpg';
import masha1 from '../images/masha/masha1.jpg';
import masha2 from '../images/masha/masha2.jpg';
import masha3 from '../images/masha/masha3.jpg';
import masha4 from '../images/masha/masha4.jpg';
import masha5 from '../images/masha/masha5.jpg';
import masha6 from '../images/masha/masha6.jpg';
import masha7 from '../images/masha/masha7.jpg';
import masha8 from '../images/masha/masha8.jpg';
import masha9 from '../images/masha/masha9.jpg';
import masha10 from '../images/masha/masha10.jpg';
import masha11 from '../images/masha/masha11.jpg';
import masha12 from '../images/masha/masha12.jpg';

// Qatar Villa
import villa from '../images/qatarvilla/villa.jpg';
import villa1 from '../images/qatarvilla/villa1.jpg';
import villa2 from '../images/qatarvilla/villa2.jpg';
import villa3 from '../images/qatarvilla/villa3.jpg';
import villa4 from '../images/qatarvilla/villa4.jpg';
import villa5 from '../images/qatarvilla/villa5.jpg';
import villa6 from '../images/qatarvilla/villa6.jpg';
import villa7 from '../images/qatarvilla/villa7.jpg';
import villa8 from '../images/qatarvilla/villa8.jpg';
import villa9 from '../images/qatarvilla/villa9.jpg';
import villa10 from '../images/qatarvilla/villa10.jpg';
import villa11 from '../images/qatarvilla/villa11.jpg';

// Eser
import eser from '../images/eser/eser.jpg';
import eser1 from '../images/eser/eser1.jpg';
import eser2 from '../images/eser/eser2.jpg';
import eser3 from '../images/eser/eser3.jpg';
import eser4 from '../images/eser/eser4.jpg';
import eser5 from '../images/eser/eser5.jpg';
import eser6 from '../images/eser/eser6.jpg';
import eser7 from '../images/eser/eser7.jpg';
import eser8 from '../images/eser/eser8.jpg';
import eser9 from '../images/eser/eser9.jpg';
import eser10 from '../images/eser/eser10.jpg';
import eser11 from '../images/eser/eser11.jpg';
import eser12 from '../images/eser/eser12.jpg';
import eser13 from '../images/eser/eser13.jpg';
import eser14 from '../images/eser/eser14.jpg';
import eser15 from '../images/eser/eser15.jpg';
import eser16 from '../images/eser/eser16.jpg';
import eser17 from '../images/eser/eser17.jpg';
import eser18 from '../images/eser/eser18.jpg';
import eser19 from '../images/eser/eser19.jpg';
import eser20 from '../images/eser/eser20.jpg';
import eser21 from '../images/eser/eser21.jpg';
import eser22 from '../images/eser/eser22.jpg';
import eser23 from '../images/eser/eser23.jpg';

// Furkat
import furkat from '../images/furkat/furkat.jpg';
import furkat1 from '../images/furkat/furkat1.jpg';
import furkat2 from '../images/furkat/furkat2.jpg';
import furkat3 from '../images/furkat/furkat3.jpg';

const projects = [
    // 1 Park
    {
        id: 1,
        title: '1 Park',
        img: park,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: park1
            },
            {
                id: 2,
                image: park2
            },
            {
                id: 3,
                image: park3
            },
            {
                id: 4,
                image: park4
            },
            {
                id: 5,
                image: park5
            },
            {
                id: 6,
                image: park6
            },
            {
                id: 7,
                image: park7
            },
            {
                id: 8,
                image: park8
            }
        ]
    },
    // 15 Tapgyr
    {
        id: 2,
        title: '15 tapgyr',
        img: tap,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: tap1
            },
            {
                id: 2,
                image: tap2
            },
            {
                id: 3,
                image: tap3
            },
            {
                id: 4,
                image: tap4
            },
            {
                id: 5,
                image: tap5
            },
            {
                id: 6,
                image: tap6
            },
            {
                id: 7,
                image: tap7
            },
            {
                id: 8,
                image: tap8
            },
            {
                id: 9,
                image: tap9
            },
            {
                id: 10,
                image: tap10
            },
            {
                id: 11,
                image: tap11
            },
        ]
    },
    // 30 Mkr
    {
        id: 3,
        title: '30 Mkr',
        img: mkr,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: mkr1
            },
            {
                id: 2,
                image: mkr2
            },
            {
                id: 3,
                image: mkr3
            },
            {
                id: 4,
                image: mkr4
            },
            {
                id: 5,
                image: mkr5
            },
            {
                id: 6,
                image: mkr6
            }
        ]
    },
    // Shor
    {
        id: 4,
        title: 'Shor Garadamak',
        img: shor,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: shor1
            },
            {
                id: 2,
                image: shor2
            },
            {
                id: 3,
                image: shor3
            }
        ]
    },
    // Nur
    {
        id: 5,
        title: 'Nur',
        img: nur2,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: nur1,
            },
            {
                id: 2,
                image: nur3,
            },
            {
                id: 3,
                image: nur4,
            },
            {
                id: 4,
                image: nur5,
            },
            {
                id: 5,
                image: nur6,
            },
            {
                id: 6,
                image: nur7,
            },
            {
                id: 7,
                image: nur8,
            },
            {
                id: 8,
                image: nur9,
            },
            {
                id: 9,
                image: nur10,
            },
            {
                id: 10,
                image: nur11,
            },
            {
                id: 11,
                image: nur12,
            },
            {
                id: 12,
                image: nur13,
            },
            {
                id: 13,
                image: nur14,
            },
            {
                id: 14,
                image: nur15
            },
            {
                id: 15,
                image: nur16
            },
            {
                id: 16,
                image: nur17
            },
            {
                id: 17,
                image: nur18
            },
            {
                id: 18,
                image: nur19
            }
        ]
    },
    // Coffee Point
    {
        id: 6,
        title: 'Coffee Point',
        img: coffee1,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: coffee2
            },
            {
                id: 2,
                image: coffee3
            },
            {
                id: 3,
                image: coffee4
            },
            {
                id: 4,
                image: coffee5
            },
            {
                id: 5,
                image: coffee6
            },
            {
                id: 6,
                image: coffee7
            },
            {
                id: 7,
                image: coffee8
            }
        ]
    },
    // Nur Karaoke
    {
        id: 7,
        title: 'Nur Karaoke',
        img: nurka,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: nurka1
            },
            {
                id: 2,
                image: nurka2
            },
            {
                id: 3,
                image: nurka3
            },
            {
                id: 4,
                image: nurka4
            },
            {
                id: 5,
                image: nurka5
            },
            {
                id: 6,
                image: nurka6
            },
            {
                id: 7,
                image: nurka7
            },
            {
                id: 8,
                image: nurka8
            },
            {
                id: 9,
                image: nurka9
            },
            {
                id: 10,
                image: nurka10
            },
            {
                id: 11,
                image: nurka11
            },
            {
                id: 12,
                image: nurka12
            },
            {
                id: 13,
                image: nurka13
            },
            {
                id: 14,
                image: nurka14
            },
            {
                id: 15,
                image: nurka15
            },
            {
                id: 16,
                image: nurka16
            },
            {
                id: 17,
                image: nurka17
            },
            {
                id: 18,
                image: nurka18
            },
            {
                id: 19,
                image: nurka19
            }
        ]
    },
    // Masha Boutique
    {
        id: 8,
        title: 'Masha Boutique',
        img: masha,
        category: 'exhibition stands design',
        images: [
            {
                id: 1,
                image: masha1
            },
            {
                id: 2,
                image: masha2
            },
            {
                id: 3,
                image: masha3
            },
            {
                id: 4,
                image: masha4
            },
            {
                id: 5,
                image: masha5
            },
            {
                id: 6,
                image: masha6
            },
            {
                id: 7,
                image: masha7
            },
            {
                id: 8,
                image: masha8
            },
            {
                id: 9,
                image: masha9
            },
            {
                id: 10,
                image: masha10
            },
            {
                id: 11,
                image: masha11
            },
            {
                id: 12,
                image: masha12
            }
        ]
    },
    // Vip Zone
    {
        id: 9,
        title: 'Vip Zone',
        img: vip5,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: vip2
            },
            {
                id: 2,
                image: vip3
            },
            {
                id: 3,
                image: vip4
            },
            {
                id: 4,
                image: vip1
            },
            {
                id: 5,
                image: vip6
            }
        ]
    },
    // Qatar Villa
    {
        id: 10,
        title: 'Qatar Villa',
        img: villa,
        category: 'interior design',
        images: [
            {
                id: 2,
                image: villa2
            },
            {
                id: 3,
                image: villa3
            },
            {
                id: 4,
                image: villa4
            },
            {
                id: 5,
                image: villa5
            },
            {
                id: 1,
                image: villa1
            },
            {
                id: 6,
                image: villa6
            },
            {
                id: 7,
                image: villa7
            },
            {
                id: 8,
                image: villa8
            },
            {
                id: 9,
                image: villa9
            },
            {
                id: 10,
                image: villa10
            },
            {
                id: 11,
                image: villa11
            }
        ]
    },
    // Eser
    {
        id: 11,
        title: "Eser",
        img: eser,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: eser1
            },
            {
                id: 2,
                image: eser2
            },
            {
                id: 3,
                image: eser3
            },
            {
                id: 4,
                image: eser4
            },
            {
                id: 5,
                image: eser5
            },
            {
                id: 6,
                image: eser6
            },
            {
                id: 7,
                image: eser7
            },
            {
                id: 8,
                image: eser8
            },
            {
                id: 9,
                image: eser9
            },
            {
                id: 10,
                image: eser10
            },
            {
                id: 11,
                image: eser11
            },
            {
                id: 12,
                image: eser12
            },
            {
                id: 13,
                image: eser13
            },
            {
                id: 14,
                image: eser14
            },
            {
                id: 15,
                image: eser15
            },
            {
                id: 16,
                image: eser16
            },
            {
                id: 17,
                image: eser17
            },
            {
                id: 18,
                image: eser18
            },
            {
                id: 19,
                image: eser19
            },
            {
                id: 20,
                image: eser20
            },
            {
                id: 21,
                image: eser21
            },
            {
                id: 22,
                image: eser22
            },
            {
                id: 23,
                image: eser23
            },
        ]
    },
    // Furkat Room
    {
        id: 12,
        title: 'Furkat Room',
        img: furkat,
        category: 'interior design',
        images: [
            {
                id: 1,
                image: furkat1
            },
            {
                id: 2,
                image: furkat2
            },
            {
                id: 3,
                image: furkat3
            }
        ]
    }
];

export default projects;