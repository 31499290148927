const pages = [
    {
        id: 1,
        title: 'home',
        path: '/'
    },
    {
        id: 2,
        title: 'portfolio',
        path: '/portfolio',
    },
    {
        id: 3,
        title: 'about us',
        path: '/about'
    },
    {
        id: 4,
        title: 'services',
        path: '/services'
    },
    {
        id: 5,
        title: 'contact',
        path: '/contact'
    }
];

export default pages;