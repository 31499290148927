import services from '../assets/data/services';
import image1 from '../assets/images/coffepoint/coffeepoint-1.jpg';
import '../styles/services.css';

const Services = () => {
    return (
        <section id={'services'}>
            <div className="container">
                <div className='content'>
                    <img className='serviceImage' src={image1} alt={'service'} />
                    <div className='serviceContent'>
                        <h1 className='heading'>We provide a full range of interior design services and prefer to be included in the project team from the earliest stage.</h1>
                        <ul className='serviceList'>
                            { services.map(service => {
                                return <li className={'serviceItem'} key={service.id}>{service.title}</li>
                            })}
                        </ul>
                    </div> 
                </div>
            </div>
        </section>
    )
};

export default Services;