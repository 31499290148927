import { Link } from 'react-router-dom';
import PinterestGrid from 'rc-pinterest-grid';

const PortfolioGrid= ({projects}) => {
    const width = window.innerWidth;
    let imageSize;
    let columns;

    if(width > 1440){
        imageSize = 420;
        columns = 3;
    }
    if(width <= 1440){
        imageSize = 420;
        columns = 3;
    }
    if(width <= 768){
        imageSize = 300
        columns = 2
    }
    if(width <= 540){
        imageSize = '100%';
        columns = 1
    }

    return (
        <PinterestGrid
            columns={columns}             
            columnWidth={imageSize}     
            gutterWidth={10}     
            gutterHeight={10}   
            responsive={width > 1440 ? false : true} 
        >
            {
                projects.map(p => {
                    return (
                        <div key={p.id}>
                        <Link to={`/portfolio/${p.id}`} >
                            <img src={p.img} alt={`${p.title}`}/>
                        </Link>
                        </div>
                    )
                })
            }
        </PinterestGrid>
    )
};

export default PortfolioGrid;