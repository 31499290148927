import { NavLink, useLocation, Link } from 'react-router-dom';
import { Logo } from '../assets/icons/icons';
import pages from '../assets/data/pages';
import '../styles/navigation.css';
import useToggle from '../hooks/useToggle';

const Navbar = () => {
    const { show, toggle } = useToggle(false);
    const location = useLocation();
    const path = location.pathname === '/' || location.pathname === '/about';

    const closeNavbar = () => {
        toggle(false);
    }

    return (
        <nav>
            <div className={'navWrapper'}>
                <Link className='logo' to={'/'}>
                    <Logo/>
                </Link>
                <button className={show ? 'burger active' : 'burger'} onClick={toggle}> 
                    <span style={{backgroundColor: path && 'var(--white)'}}></span>
                    <span style={{backgroundColor: path && 'var(--white)'}}></span>
                    <span style={{backgroundColor: path && 'var(--white)'}}></span>
                </button>
                <ul className={'navList'} style={{right: show ? `0%` : `-200%`}}>
                    { pages.map(page => {
                        const { id, title, path} = page;
                        return (
                            <li key={id} className={'navItem'}>
                                <NavLink to={path} className={'navLink'} onClick={() => closeNavbar()}>{title}</NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
};

export default Navbar;