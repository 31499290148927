import { Tag, TagGroup } from 'rsuite';
import { useState } from 'react';
import projects from '../assets/data/projects';
import { PortfolioGrid } from '../components';
import '../styles/portfolio.css';

const tagList = ['all', ...new Set(projects.map(project => project.category))];

const Portfolio = () => {
    const [ tags, setTags ] = useState(tagList);
    const [ portfolio, setPortfolio ] = useState(projects);

    const filterTags = (tag) => {
        if(tag === 'all'){
            setPortfolio(projects);
            return;
        };

        const newPortfolio = projects.filter(project => project.category === tag);
        setPortfolio(newPortfolio);
    }

    return (
        <section id={'portfolio'}>
            <div className={'container'}>
                <div className={'content'}>
                    <div className={'portfolioHeading'}>
                        <h1 className={'heading'}>Our projects</h1>
                        <TagGroup>
                            { tags.map((tag, i)=> {
                                return (
                                    <Tag 
                                        key={i}
                                        style={tagStyle}  
                                        onClick={() => filterTags(tag)}
                                        className={'projectTag'}
                                    >{tag}</Tag>
                                )
                            })}
                        </TagGroup>
                    </div>
                </div>
            </div>
            <div className={'projects'}>
                <PortfolioGrid projects={portfolio}/>
            </div>
        </section>
    )
};

const tagStyle = {
    backgroundColor: '#184D473B',
    borderRadius: '30px',
    color: 'var(--darkGreen)',
    fontSize: '14px',
    fontFamily: 'var(--Poppins)',
    fontWeight: '300',
    cursor: 'pointer'
}

export default Portfolio;