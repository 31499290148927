const services = [
    {
        id: 1,
        title: 'Interior design'
    },
    {
        id: 2,
        title: "Exterior design"
    },
    {
        id: 3,
        title: "Landshaft design"
    },
    {
        id: 4,
        title: "Architecture"
    },
    {
        id: 5,
        title: "Schematic design"
    },
    {
        id: 6,
        title: "Turnkey repair"
    },
    {
        id: 7,
        title: "Author's supervision"
    },
    {
        id: 8,
        title: "Exhibition stands"
    },
    {
        id: 9,
        title: "Restaurants & Offices"
    }
];

export default services;