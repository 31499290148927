import services from '../assets/data/services';
import '../styles/contact.css';

const Contact = () => {
    return (
        <section id={'contact'}>
            <div className={'container'}>
                <div className='content'>
                    <h1 className={'heading'}>Let's talk about your project</h1>
                </div>
                <div className={'content'}>
                    <div className={'address'}>
                        <h1 className={'contactHeading'}>Garashsyzlyk Ave. 90
                            <br/>
                            "Vestel" building
                            <br/>
                            Ashgabat, Turkmenistan
                        </h1>
                        <h3>Qatar representative</h3>
                        <p>
                            Hanson International,
                            <br/>
                            <a href={'tel:+97440013017'}>+974 4001 3017</a>,
                            <br/>
                            P. O. Box 35256,
                            <br/>
                            New Rayyan, Shafi st. Doha, Qatar.
                        </p>
                    </div>
                    <div className={'contact'}>
                        <div className={"form"}>
                            <h1 className={'contactHeading'}>
                                <a href={'tel:+99312212040'}>+993 12 21 20 40</a>
                                <br/>
                                <a href={'mailto:info@caspy.biz'}>info@caspy.biz</a>
                                <br/>
                                <a href={'mailto:@caspy.concept'}>@caspy.concept</a>
                            </h1>
                            <div className={'contactForm'}>
                                <input placeholder={'Your name'}/>
                                <select>
                                    { services.map(service => {
                                        return <option key={service.id}>{service.title}</option>
                                    })}
                                </select>
                                <textarea placeholder={'Message...'} rows={4}></textarea>
                                <button>Send mail</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
};

export default Contact;