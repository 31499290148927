import { useParams } from 'react-router-dom';
import projects from '../assets/data/projects';
import PinterestGrid from 'rc-pinterest-grid/lib/Grid';
import '../styles/portfolio.css';

const Detail = () => {
    const { id } = useParams();
    const project = projects.find(project => project.id === JSON.parse(id));
    const width = window.innerWidth;
    let imageSize;

    if(width >= 768){
        imageSize = 340
    }
    if(width > 1024){
        imageSize = 630
    }
    if(width <= 540){
        imageSize = '100%'
    }

    return (
        <section id="detail">
            <div className='projectImages'>
                <PinterestGrid 
                    columns={width >= 768 ? 2 : 1}             
                    columnWidth={imageSize || 630}     
                    gutterWidth={10}     
                    gutterHeight={10}   
                    responsive={true} 
                >
                { project.images.map(image => {
                    return (
                        <div key={image.id}>
                            <img src={image.image} alt={image.id} />
                        </div>
                    )
                })}
                </PinterestGrid>
            </div>
        </section>
    )
};

export default Detail;