import { useState } from 'react';

const useToggle = (defaultValue) => {
    const [ show, setShow ] = useState(defaultValue);

    const toggle = () => {
        setShow(!show);
    };

    return { show, toggle };
};

export default useToggle;