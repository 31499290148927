import park2 from '../../assets/images/1park/park2.jpg';
import furkat from '../../assets/images/furkat/furkat2.jpg';
import tap10 from '../../assets/images/15tapgyr/tap10.jpg';
import nurka from '../../assets/images/Nur Karaoke/nurka.jpg';
import './verticalSlider.css';
import Carousel from 'react-elastic-carousel';
import Indicator from '../Indicator/Indicator';

const VerticalSlider = () => {
    const width = window.innerWidth;

    return (
        <Carousel 
            verticalMode 
            itemsToShow={1}
            initialActiveIndex={0}
            showArrows={false}
            enableSwipe={width < 425 ? true : false}
            renderPagination={({pages, activePage, onClick}) => {
                return <Indicator pages={pages} active={activePage} onClick={onClick}/>
            }}>
            <img src={park2} className='carouselImage' alt={'carouselImage'} />
            <img src={tap10}  className='carouselImage' alt={'carouselImage'} />
            <img src={furkat}  className='carouselImage' alt={'carouselImage'} />
            <img src={nurka}  className='carouselImage' alt={'carouselImage'} />
        </Carousel>
    )
};

export default VerticalSlider;