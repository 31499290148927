import { VerticalSlider } from '../components';
import '../styles/home.css';

const Home = () => {
    return (
        <section id={'home'}>
            <div className='slider'>
                <VerticalSlider/>
            </div>
            <div className={'container'}>
                <div className={'content'}>
                    <h1 className={'heading'}>We create <span>home</span> more aesthetic</h1>
                </div>
            </div>
        </section>
    )
};

export default Home;